

import React, { useState } from 'react';
import { FaBars, FaTimes, FaSun, FaMoon } from 'react-icons/fa';
import logo_black from '../Images/logo_black.png';
import logo_white from '../Images/logo_white.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleToggle = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      document.documentElement.classList.toggle('dark', newMode);
      return newMode;
    });
  };

  return (
    <>
      <header className="bg-white mb-16 shadow-md fixed top-0 w-full z-50">
        <div className="container mx-auto px-0 py-0 flex items-center justify-between">
          {/* Logo */}
          <div className="mx-12">
            <img src={logo_black} alt="Logo" className=" h-32 w-32" />
          </div>

          {/* Toggle Button for Mobile Menu */}
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className=" text-gray-700 dark:text-gray-300 focus:outline-none"
            >
              {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>

          {/* Navigation Menu */}
          <nav
            className={`${
              menuOpen ? 'translate-x-0' : 'translate-x-full'
            } md:translate-x-4 md:flex flex-col md:flex-row md:text-center md:gap-8 absolute md:relative left-0 top-full w-full md:w-auto bg-white md:bg-transparent shadow-md md:shadow-none py-4 md:py-0 z-50 transition-transform duration-300 ease-in-out flex items-center justify-center`}
          >
            <div className="flex flex-col md:flex-row md:items-center md:gap-8">
              <h2 className="text-gray-700 dark:text-gray-300 text-lg md:text-2xl px-4 md:px-0">Industries</h2>
              <h2 className="text-gray-700 dark:text-gray-300 text-lg md:text-2xl px-4 md:px-0">Products</h2>
              <h2 className="text-gray-700 dark:text-gray-300 text-lg md:text-2xl px-4 md:px-0">Services</h2>
              <h2 className="text-gray-700 dark:text-gray-300 text-lg md:text-2xl px-4 md:px-0">Portfolio</h2>
              <h2 className="text-gray-700 dark:text-gray-300 text-lg md:text-2xl px-4 md:px-0">Resources</h2>
            </div>
            {/* Theme Toggle Button in Menu */}
            <button
              onClick={handleToggle}
              className="p-2 mr-5 bg-gray-200 rounded-full dark:bg-gray-700 text-gray-400 dark:text-gray-200 transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4 md:mt-0"
              aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              {isDarkMode ? <FaSun className="w-5 h-5 md:w-6 md:h-6" /> : <FaMoon className="w-5 h-5 md:w-6 md:h-6" />}
            </button>
          </nav>

          {/* Right-side Item: Visit Button */}
          <div className="hidden md:flex items-center gap-4">
            <button
              className="text-white px-4 py-2 rounded-md"
              style={{
                backgroundColor: '#1B4D3E',
                transition: 'background-color 0.3s ease',
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = '#163BAD')}
              onMouseOut={(e) => (e.target.style.backgroundColor = '#1B4D3E')}
            >
              Visit
            </button>
          </div>
        </div>
      </header>

      {/* Placeholder for main content */}
      <div style={{ paddingTop: '8rem', position: 'relative' }}>
        <img
          src="https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65f325b003a74d70ff87b9ee_opt_2_AdobeStock_673821157.webp"
          loading="eager"
          sizes="100vw , 70vh"
          srcSet="https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65f325b003a74d70ff87b9ee_opt_2_AdobeStock_673821157-p-500.webp 500w, https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65f325b003a74d70ff87b9ee_opt_2_AdobeStock_673821157-p-800.webp 800w, https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65f325b003a74d70ff87b9ee_opt_2_AdobeStock_673821157-p-1080.webp 1080w, https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65f325b003a74d70ff87b9ee_opt_2_AdobeStock_673821157-p-1600.webp 1600w, https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65f325b003a74d70ff87b9ee_opt_2_AdobeStock_673821157-p-2000.webp 2000w, https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65f325b003a74d70ff87b9ee_opt_2_AdobeStock_673821157-p-2600.webp 2600w, https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65f325b003a74d70ff87b9ee_opt_2_AdobeStock_673821157-p-3200.webp 3200w, https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65f325b003a74d70ff87b9ee_opt_2_AdobeStock_673821157.webp 5824w"
          alt=""
          className="image---hero"
          style={{
            height: '600px',
            objectFit: 'cover',
            width: '100%',
          }}
        />
        <img
          src={logo_white}
          alt="Nextbitt Logo"
          className="h-32 w-32 mt-24"
          style={{
            position: 'absolute',
            top: '50%',
            left: '0',
            transform: 'translateY(-50%)',
            height: '500px',
            width: '500px',
          }}
        />
      </div>

      <section
        className="flex flex-col md:flex-row justify-between items-center bg-[#CAE00D] p-4 md:p-10"
      >
        {/* Header Section */}
        <div className="flex-1">
          <h1 className="text-2xl md:text-5xl font-bold leading-tight text-left md:mx-12 lg:mx-44 text-black">
            FURIOUS EXPORTS
            <br />
            Private Limited.
          </h1>
        </div>
        
        {/* Content Section */}
        <div className="flex-1 mt-6 md:mt-0">
          <p className="text-base md:text-xl max-w-full md:max-w-lg text-justify md:mt-12 text-black mx-4 md:mx-0">
            As a seasoned onion exporter with years of expertise in the industry, I am thrilled to announce the launch of our new website, where we aim to bring our premium quality onions directly to you.
            <br />
            <br />
            Welcome to Furious Exports Pvt Ltd, your trusted partner in freshness and flavor.
          </p>
          
          <div className="flex justify-center md:justify-start">
            <button
              className="text-white px-4 py-2 rounded-md mt-6 md:mt-10 h-16 w-28 md:h-14 md:w-32"
              style={{
                backgroundColor: '#1B4D3E',
                transition: 'background-color 0.3s ease',
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = '#163BAD')}
              onMouseOut={(e) => (e.target.style.backgroundColor = '#1B4D3E')}
            >
              Show Demo
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;






