import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import qualityImage from '../Images/quality.png';
import globe from '../Images/globe.png';
import customer from '../Images/customer.png';
import expteam from '../Images/expteam.png';
import sustain from '../Images/sustain.png';
import technology from '../Images/technology.png';

function WhyUs() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default number of slides to show
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 1024, // Tablet and below
        settings: {
          slidesToShow: 2, // Show 2 slides on medium-sized screens
        },
      },
      {
        breakpoint: 768, // Mobile and below
        settings: {
          slidesToShow: 1, // Show 1 slide on small screens
        },
      },
    ],
  };

  const cards = [
    {
      image: qualityImage,
      heading: "Quality Assurance",
      description: "We provide the highest quality onions with strict quality control measures ensuring only the best reach our customers.",
    },
    {
      image: globe,
      heading: "Global Export Network",
      description: "Our extensive global network ensures timely delivery and consistency in supply to meet customer demands worldwide.",
    },
    {
      image: sustain,
      heading: "Sustainable Practices",
      description: "We employ eco-friendly farming and harvesting methods to maintain sustainability and environmental health.",
    },
    {
      image: expteam,
      heading: "Experienced Team",
      description: "Our team of experts has decades of experience in the onion export industry, ensuring professionalism and expertise.",
    },
    {
      image: customer,
      heading: "Customer Satisfaction",
      description: "We prioritize customer satisfaction with our dedicated customer support and commitment to excellence.",
    },
    {
      image: technology,
      heading: "Advanced Technology",
      description: "Using the latest technology in sorting, grading, and packaging to maintain the freshness and quality of our onions.",
    },
  ];

  return (
    <div className="py-10">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-8">Why Choose Us</h2>
        <Slider {...settings}>
          {cards.map((card, index) => {
            const isMiddleCard = index === currentSlide + 1 || (currentSlide === cards.length - 1 && index === 0);
            return (
              <div key={index} className={`p-4 ${isMiddleCard ? "transform scale-105" : "transform scale-100"}`}>
                <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full h-[400px] flex flex-col">
                  {/* Responsive image size and centering */}
                  <img src={card.image} alt={card.heading} className="w-[150px] h-[150px] md:w-[220px] md:h-[220px] object-cover mx-auto my-4" />
                  
                  <div className="p-4 flex flex-col justify-between flex-grow">
                    <h3 className="text-lg md:text-xl font-bold mb-2">{card.heading}</h3>
                    <p className="text-sm md:text-base text-gray-700 dark:text-gray-300">{card.description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default WhyUs;
