import AboutUs from './Components/AboutUs';
import Benifits from './Components/Benifits';
import ContactUs from './Components/Contact';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Process from './Components/Process';
// import Toggle from './Components/Toggle';
import WhyUs from './Components/WhyUs';
import WhyUs2 from './Components/WhyUs2';

function App() {
  return (
    <div>
      <Header />
      <Benifits />
      {/* <Toggle /> */}
      <Process />
      <AboutUs />
      <WhyUs />
      <WhyUs2 />
      <ContactUs />
      <Footer />
    </div>

  )
}

export default App;
