import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import leftImage from '../Images/purchase.webp';

const numberedImage1 = 'https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65c4f6b135655a27c095ff01_01.webp';
const numberedImage2 = 'https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65c5f7504676e29dddef693c_02.webp';
const photoCard1 = 'https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65c3cbc8f53f0555691280e7_AdobeStock_624676791.webp';
const photoCard2 = 'https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65c4a2eaf27985c8d72743ce_AdobeStock_288383740.webp';
const photoCard3 = 'https://cdn.prod.website-files.com/65b7981897ba2e51087129d0/65c4a23e601f97bd476be420_AdobeStock_252816096.webp';

gsap.registerPlugin(ScrollTrigger);

const Process = () => {
  const lineRef1 = useRef(null);
  const lineRef2 = useRef(null);

  useEffect(() => {
    const line1 = lineRef1.current;
    const line2 = lineRef2.current;

    gsap.fromTo(
      line1,
      { opacity: 0, height: 0 },
      {
        opacity: 1,
        height: '100%',
        duration: 1,
        scrollTrigger: { trigger: line1, start: 'top center', end: 'bottom center', scrub: true },
      }
    );

    gsap.fromTo(
      line2,
      { opacity: 0, height: 0 },
      {
        opacity: 1,
        height: '100%',
        duration: 1,
        scrollTrigger: { trigger: line2, start: 'top center', end: 'bottom center', scrub: true },
      }
    );
  }, []);

  return (
    <>
      <h1 className="text-4xl text-center my-20 mt-36">This is how our Process look's like.</h1>

      {/* Card Section */}
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 mb-16 px-4 md:px-0">
        <div className="bg-[#7064DF] rounded-3xl overflow-hidden shadow-lg h-[500px] md:h-[600px] text-white">
          <img src={photoCard1} alt="Card 1" className="w-full h-1/2 object-cover rounded-t-3xl" />
          <div className="p-4 h-1/2">
            <h2 className="text-xl font-bold mb-2 mt-10">Card Title 1</h2>
            <p className="text-base">
              A short description for the first card goes here.
            </p>
          </div>
        </div>

        <div className="bg-[#092a22] rounded-3xl overflow-hidden shadow-lg h-[500px] md:h-[600px] text-white">
          <img src={photoCard2} alt="Card 2" className="w-full h-1/2 object-cover rounded-t-3xl" />
          <div className="p-4 h-1/2">
            <h2 className="text-xl font-bold mb-2 mt-10">Card Title 2</h2>
            <p className="text-base">
              A short description for the second card goes here.
            </p>
          </div>
        </div>

        <div className="bg-[#8AC7DB] rounded-3xl overflow-hidden shadow-lg h-[500px] md:h-[600px]">
          <img src={photoCard3} alt="Card 3" className="w-full h-1/2 object-cover rounded-t-3xl" />
          <div className="p-4 h-1/2">
            <h2 className="text-xl font-bold mb-2 mt-10">Card Title 3</h2>
            <p className="text-base">
              A short description for the third card goes here.
            </p>
          </div>
        </div>
      </div>

      {/* Process Step 1 */}
      <section className="relative py-16">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4 md:px-0">
          <div className="flex items-center justify-center">
            <img src={leftImage} alt="Process Step 1" className="w-60 h-60 md:w-80 md:h-80" />
          </div>
          <div className="relative flex items-center justify-center">
            <img src={numberedImage1} alt="Process Number 1" className="w-16 h-16 md:w-24 md:h-24 z-10" />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-1 bg-gray-400 h-full" ref={lineRef1}></div>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <h1 className="text-2xl md:text-3xl text-[#333] mb-4 ml-4 md:ml-20 font-mono dark:text-gray-300">Purchasing</h1>
            <p className="text-base md:text-lg text-[#333] ml-4 md:ml-20 dark:text-gray-300">
              Network of farms, market and auctions allow us to source best quality product.
              Expertise purchasers in markets and auctions. Strict quality control at point of
              purchase which help us to maintain quality packing.
            </p>
          </div>
        </div>
      </section>

      {/* Process Step 2 */}
      <section className="relative py-16">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4 md:px-0">
          <div className="flex flex-col items-start order-2 md:order-1">
            <h1 className="text-2xl md:text-3xl text-[#1B4D3E] mb-4 ml-4 md:ml-20 font-mono dark:text-gray-300">Grading</h1>
            <p className="text-base md:text-lg text-[#1B4D3E] ml-4 md:ml-20 dark:text-gray-300">
              Our well-trained labor sort goods into different sizes and are specially trained to handle that work. Quality control team inspects conveyors and removes damaged onions. Our labor grades the size properly by our customer requirement.
            </p>
          </div>

          <div className="relative flex items-center justify-center order-1 md:order-2">
            <img src={numberedImage2} alt="Process Number 2" className="w-16 h-16 md:w-24 md:h-24 z-10" />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-1 bg-gray-400 h-full" ref={lineRef2}></div>
            </div>
          </div>
          <div className="flex items-center justify-center order-3">
            <img src={leftImage} alt="Process Step 2" className="w-60 h-60 md:w-80 md:h-80" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Process;
