import React from 'react';
import quality from '../Images/quality.png';
import customer from '../Images/customer.png';
import saving from '../Images/saving.png';
import onion from '../Images/onion.png';

function Benifits() {
  return (
    <section className="bg-[#CAE00D] py-8 md:py-16">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 md:gap-8 px-4">
        <div className="bg-[#d5ec5b] rounded-lg p-6 md:p-8 text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img src={quality} alt='Quality' className="mx-auto mb-4 w-16 h-16 md:w-20 md:h-20" />
          <div className="text-2xl md:text-3xl font-semibold text-[#004e3f] mb-2 md:mb-4 text-start">Assured Quality</div>
          <p className="text-[#004e3f] text-justify text-base md:text-lg">
            Assured quality with a dedicated team of professionals we can provide the best quality goods.
          </p>
        </div>

        <div className="bg-[#d5ec5b] rounded-lg p-6 md:p-8 text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img src={customer} alt='Customer Satisfaction' className="mx-auto mb-4 w-16 h-16 md:w-20 md:h-20" />
          <div className="text-2xl md:text-3xl font-semibold text-[#004e3f] mb-2 md:mb-4 text-start">Customer Satisfaction</div>
          <p className="text-[#004e3f] text-justify text-base md:text-lg">
            Packing from 500 grams to 50 kg, we satisfy all our customers' needs with all types of bags.
          </p>
        </div>

        <div className="bg-[#d5ec5b] rounded-lg p-6 md:p-8 text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img src={saving} alt='Competitive Rates' className="mx-auto mb-4 w-16 h-16 md:w-20 md:h-20" />
          <div className="text-2xl md:text-3xl font-semibold text-[#004e3f] mb-2 md:mb-4 text-start">Competitive Rates</div>
          <p className="text-[#004e3f] text-justify text-base md:text-lg">
            With large scale procurement, we can provide the best rates in the market, our purchasing is from ground level.
          </p>
        </div>

        <div className="bg-[#d5ec5b] rounded-lg p-6 md:p-8 text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img src={onion} alt='Products We Export' className="mx-auto mb-4 w-16 h-16 md:w-20 md:h-20" />
          <div className="text-2xl md:text-3xl font-semibold text-[#004e3f] mb-2 md:mb-4 text-start">Products We Export</div>
          <p className="text-[#004e3f] text-justify text-base md:text-lg">
            Red onion, sambar onion, shallots, white onion, potatoes, garlic, ginger.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Benifits;
