import React from "react";
import weight from "../Images/weight.png";
import grading from "../Images/grading.png";

function AboutUs() {
    return (
        <>
            <div className="bg-[#CAE00D] py-10">
                <div className="container mx-auto text-center px-4">
                    <h2 className="text-4xl md:text-5xl font-bold font-mono mb-4 text-black">About Us</h2>
                    <p className="text-justify text-base md:text-lg font-semibold max-w-2xl mx-auto text-black">
                        FURIOUS EXPORTS PVT LTD is one of the well-known wholesale onion exporting companies from India.
                        The business of onion is from 60 years in this market. From farm to table, we're dedicated onion enthusiasts
                        committed to delivering premium quality and flavor worldwide. With years of expertise in the onion export-domestic
                        industry, we pride ourselves on our unparalleled knowledge, reliability, and commitment to delivering the finest onions
                        to global markets.
                    </p>
                </div>
                <h1 className="text-3xl md:text-4xl mt-10 md:mt-14 text-center italic font-serif text-[#1B4D3E]">Photos of warehouses!!! And our all works!!!</h1>

                <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 mb-12 md:mb-16 mt-12 md:mt-24 px-4">
                    <div className="bg-[#7064DF] rounded-3xl overflow-hidden shadow-lg h-[400px] md:h-[600px] text-white">
                        <img src={weight} alt="Electronic Weigh Scale" className="w-full h-[200px] md:h-[400px] object-cover" />
                        <div className="p-4 h-1/2">
                            <h2 className="text-xl md:text-2xl font-bold mb-2 mt-4 md:mt-10">Electronic Weigh Scale</h2>
                            <p className="text-sm md:text-base">
                                Use of the latest electronic weigh scale to weigh each and every bag accurately.
                            </p>
                        </div>
                    </div>

                    <div className="bg-[#092a22] rounded-3xl overflow-hidden shadow-lg h-[400px] md:h-[600px] text-white">
                        <img src={grading} alt="Grading" className="w-full h-[200px] md:h-[400px] object-cover" />
                        <div className="p-4 h-1/2">
                            <h2 className="text-xl md:text-2xl font-bold mb-2 mt-4 md:mt-10">Grading</h2>
                            <p className="text-sm md:text-base">
                                Specially well-trained grading laborers for sorting out onions according to shape, weight, and size.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutUs;
