import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import qualityImage from '../Images/quality.png';
import globe from '../Images/globe.png';
import customer from '../Images/customer.png';
import expteam from '../Images/expteam.png';
import sustain from '../Images/sustain.png';
import technology from '../Images/technology.png';

function WhyUs2() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default to show 3 slides
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024, // Tablet breakpoint
        settings: {
          slidesToShow: 2, // Show 2 slides on tablets
        },
      },
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile devices
        },
      },
    ],
  };

  const certificates = [globe, qualityImage, customer, expteam, sustain, technology];

  return (
    <div className="bg-[#CAE00D] py-10">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-[#1B4C3E] mb-8 text-center">Our Certifications</h2>
        <Slider {...settings}>
          {certificates.map((image, index) => (
            <div key={index} className="p-4">
              {/* Use responsive classes to adjust image size */}
              <img src={image} alt={`Certificate ${index + 1}`} className="w-full h-auto max-w-[300px] mx-auto" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default WhyUs2;
