import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className="bg-[#1B4D3E] text-white py-8 md:py-12 flex flex-col">
      <div className="container mx-auto px-4 flex flex-col h-full">
        <div className="flex-grow flex flex-col md:flex-row items-center justify-between">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <h3 className="text-2xl md:text-3xl font-bold">Furious Exports</h3>
            <p className="text-base md:text-lg mt-2">© 2024 Nextbitt. All rights reserved.</p>
          </div>
          <div className="flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-8 text-center md:text-left">
            <nav className="flex flex-col space-y-2">
              <h4 className="font-semibold text-lg md:text-xl">Company</h4>
              <p className="text-base md:text-lg hover:text-gray-400">About Us</p>
              <p className="text-base md:text-lg hover:text-gray-400">Career</p>
              <p className="text-base md:text-lg hover:text-gray-400">News</p>
            </nav>
            <nav className="flex flex-col space-y-2">
              <h4 className="font-semibold text-lg md:text-xl">Contact Us</h4>
              <p className="text-base md:text-lg hover:text-gray-400">Contact Us</p>
              <p className="text-base md:text-lg hover:text-gray-400">Book a Demo</p>
            </nav>
          </div>
        </div>
        <div className="flex justify-center space-x-4 md:space-x-6 mt-4 text-2xl md:text-3xl">
          <a href="https://facebook.com" className="text-gray-400 hover:text-blue-600 transition-colors duration-300">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://twitter.com" className="text-gray-400 hover:text-blue-400 transition-colors duration-300">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://instagram.com" className="text-gray-400 hover:text-pink-500 transition-colors duration-300">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://linkedin.com" className="text-gray-400 hover:text-blue-700 transition-colors duration-300">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
        <div className="text-center text-base md:text-xl mt-4 md:mt-6">
          <p className="inline-block">28°C | Partly sunny</p>
          <p className="inline-block ml-2 md:ml-4">Ahmednagar, India</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
